// @ts-check

/**
 * Sync the `--vph` CSS variable to the viewport height
 *
 * Fixes issues with 100vh on mobile browsers where the address bar is visible.
 *
 * Note iOS Safari can use `-webkit-fill-available` as a workaround, but Android Chrome does not support this.
 */
export function syncViewportHeightProperty() {
  const rootEl = document.documentElement;

  // We don't need to do any of this if the browser supports modern viewport units
  // https://developer.mozilla.org/en-US/docs/Web/CSS/length#vh
  // https://caniuse.com/viewport-unit-variants
  if (CSS?.supports("height", "100dvh")) {
    rootEl.style.setProperty("--vph", "100dvh");
    return () => {};
  }

  return syncElementHeight(rootEl, "vph");
}

/** Syncs the `--${prop}` CSS variable to the element's height */
export function syncElementHeight(el, prop = "h") {
  let prevClientHeight;
  const resizeObserver = new ResizeObserver(function updateHeight(entries) {
    const clientHeight = entries[0].contentRect.height;
    console.log(`--${prop}: ${clientHeight}px`, entries[0])
    if (clientHeight === prevClientHeight) return;

    el.style.setProperty(`--${prop}`, `${clientHeight}px`);
    prevClientHeight = clientHeight;
  });

  resizeObserver.observe(el);

  return function disconnectObserver() {
    resizeObserver.unobserve(el);
  };
}

let scrollY;
let scrollRoot;
export function setScrollLock(lock = true) {
  scrollRoot ??= document.scrollingElement || document.documentElement;
  const bodyClass = document.documentElement.classList;
  if (lock && !bodyClass.contains("scroll-lock")) {
    scrollY = window.scrollY;
    bodyClass.add("scroll-lock");
  } else if (!lock) {
    bodyClass.remove("scroll-lock");
    requestAnimationFrame(() => window.scrollTo(0, scrollY));
  }
}
