import { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { setScrollLock } from "~lib/viewport";

const useIsomorphicLayoutEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect;

export const OverlayContext = createContext({
  state: {
    enabled: false
  },
  setState: () => {},
});

export const OverlayProvider = ({ children }) => {
  const [state, setState] = useState({ enabled: false });

  // Prevent user from scrolling page body while overlay is active
  useIsomorphicLayoutEffect(() => {
    setScrollLock(state.enabled);
    return () => setScrollLock(false);
  }, [state.enabled]);

 return (
   <OverlayContext.Provider value={{ state, setState }}>
     {children}
   </OverlayContext.Provider>
 );
}

export function useOverlay() {
  const { state, setState } = useContext(OverlayContext);

  return {
    enabled: state.enabled,
    setEnabled: (val) => setState((prev) => ({ ...prev, enabled: val })),
  };
}
